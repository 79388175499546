interface DashCardMobileCoverProps {
    isMobile?: boolean;
}

export function DashCardMobileCovers({
    isMobile = false,
}: DashCardMobileCoverProps) {
    if (isMobile) {
        return (
            <div
                style={{
                    zIndex: 10,
                    width: `100%`,
                    height: `100%`,
                    position: `absolute`,
                }}></div>
        );
    }

    return (
        <div></div>
    );



}
