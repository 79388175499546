var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var CH,DH,FH,GH,Jfa,Kfa,Lfa,HH,EH;$CLJS.AH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.BH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
CH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.xC(b,$CLJS.YC)?$CLJS.lH:$CLJS.xC(b,$CLJS.XD)?$CLJS.lE:$CLJS.xC(b,$CLJS.xD)?$CLJS.mE:null;return $CLJS.n(b)?$CLJS.wF(b,a):!0};
DH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.AH(function(d){return $CLJS.xC($CLJS.UE(d),$CLJS.iD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Mk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.UE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(CH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
FH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(DH(b))].join("")}],null),$CLJS.Te(DH)],null)],null)};
GH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null)],null)};
Jfa=function(a){return $CLJS.Qd($CLJS.zF,$CLJS.hf.g(function(b){var c=$CLJS.UE(b),d=$CLJS.xC(c,$CLJS.fF);b=d?$CLJS.wF($CLJS.tG,b):d;return $CLJS.n(b)?$CLJS.Oj:c},a))};Kfa=function(a){a=$CLJS.BH(function(b){return!$CLJS.xC(b,$CLJS.iD)},$CLJS.hf.g($CLJS.UE,a));return $CLJS.xC(a,$CLJS.fF)?$CLJS.Vj:a};
Lfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.xC($CLJS.UE(c),$CLJS.iD)},b))?Kfa(b):$CLJS.E.g(a,$CLJS.pt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.xC($CLJS.UE(c),$CLJS.YC)},b)||$CLJS.Qe(function(c){return $CLJS.xC($CLJS.UE(c),$CLJS.xD)},b))?$CLJS.iD:Jfa(b)};HH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.IH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);EH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,EH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.wt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.UE(a);return $CLJS.Qe(function(d){return CH(d,c)},b)}],null)],null));
$CLJS.X(HH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null));
var Mfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.wt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],
null);$CLJS.QG.g($CLJS.as,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,FH($CLJS.as),GH($CLJS.as)],null));$CLJS.QG.g($CLJS.pt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,FH($CLJS.pt),Mfa,GH($CLJS.pt)],null));$CLJS.GF($CLJS.bs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,HH],null)]));$CLJS.GF($CLJS.LG,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,HH],null)]));
for(var JH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.as,$CLJS.pt,$CLJS.bs],null)),KH=null,LH=0,MH=0;;)if(MH<LH){var Nfa=KH.X(null,MH);$CLJS.xF(Nfa,$CLJS.IH);MH+=1}else{var NH=$CLJS.y(JH);if(NH){var OH=NH;if($CLJS.Ad(OH)){var PH=$CLJS.lc(OH),Ofa=$CLJS.mc(OH),Pfa=PH,Qfa=$CLJS.D(PH);JH=Ofa;KH=Pfa;LH=Qfa}else{var Rfa=$CLJS.A(OH);$CLJS.xF(Rfa,$CLJS.IH);JH=$CLJS.B(OH);KH=null;LH=0}MH=0}else break}
$CLJS.TE.m(null,$CLJS.IH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Lfa(a,b)});$CLJS.EF($CLJS.QF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.xF($CLJS.QF,$CLJS.jF);
for(var QH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,$CLJS.HF,$CLJS.cG],null)),RH=null,SH=0,TH=0;;)if(TH<SH){var Sfa=RH.X(null,TH);$CLJS.EF(Sfa,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));TH+=1}else{var UH=$CLJS.y(QH);if(UH){var VH=UH;if($CLJS.Ad(VH)){var WH=$CLJS.lc(VH),Tfa=$CLJS.mc(VH),Ufa=WH,Vfa=$CLJS.D(WH);QH=Tfa;RH=Ufa;SH=Vfa}else{var Wfa=$CLJS.A(VH);$CLJS.EF(Wfa,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));QH=$CLJS.B(VH);RH=null;SH=0}TH=0}else break}
for(var XH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.nG,$CLJS.uG],null)),YH=null,ZH=0,$H=0;;)if($H<ZH){var Xfa=YH.X(null,$H);$CLJS.EF(Xfa,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$H+=1}else{var aI=$CLJS.y(XH);if(aI){var bI=aI;if($CLJS.Ad(bI)){var cI=$CLJS.lc(bI),Yfa=$CLJS.mc(bI),Zfa=cI,$fa=$CLJS.D(cI);XH=Yfa;YH=Zfa;ZH=$fa}else{var aga=$CLJS.A(bI);$CLJS.EF(aga,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));XH=$CLJS.B(bI);YH=null;ZH=0}$H=0}else break}$CLJS.EF($CLJS.NF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));
$CLJS.TE.m(null,$CLJS.NF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.xC($CLJS.UE(b),$CLJS.vj)&&$CLJS.xC($CLJS.UE(a),$CLJS.vj)?$CLJS.vj:$CLJS.UD});