var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var f1,Ina,Jna,g1,h1;$CLJS.d1=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};f1=function(a){return $CLJS.n(e1)?["(",$CLJS.p.h(a),")"].join(""):a};Ina=function(a,b,c){var d=$CLJS.LW(a,b);a=$CLJS.Q0.j(a,b,d);var e=$CLJS.cB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.cB($CLJS.T.h(f)),e)},a)};
Jna=function(a,b){var c=$CLJS.Rk.v(a,$CLJS.eQ,$CLJS.ZD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.ZE.h(a))?$CLJS.Rk.v(c,$CLJS.ZE,$CLJS.be,$CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tB,$CLJS.N,$CLJS.EW(b)],null))):c};g1=function(a,b,c){var d=$CLJS.EW(c);return $CLJS.R.l($CLJS.e0.j(a,b,c),$CLJS.VK,$CLJS.KZ,$CLJS.H([$CLJS.T,d,$CLJS.uE,d]))};h1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.i1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var j1=function(){function a(d,e,f){var k=$CLJS.LW(d,e);k=$CLJS.BH($CLJS.Ok.g($CLJS.ch([f]),$CLJS.EW),$CLJS.eQ.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.pE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.gS,f,$CLJS.MF,d,$CLJS.KL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.J0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=j1.j(a,b,c);return $CLJS.K0.j(a,b,c)});$CLJS.L0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Dj,$CLJS.TK,$CLJS.i1,$CLJS.GE.h(d),$CLJS.T,e,$CLJS.yE,e,$CLJS.uE,$CLJS.f0.j(a,b,c),$CLJS.wB,$CLJS.K0.j(a,b,c),$CLJS.VK,$CLJS.KZ],null)});$CLJS.F0.m(null,$CLJS.nB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.F0.m(null,$CLJS.lB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.F0.m(null,$CLJS.mB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.F0.m(null,$CLJS.DB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.F0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.G0.m(null,$CLJS.tB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var e1=!1,Kna=new $CLJS.h(null,4,[$CLJS.as,"+",$CLJS.pt,"-",$CLJS.bs,"×",$CLJS.LG,"÷"],null),k1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.as,$CLJS.pt,$CLJS.LG,$CLJS.bs],null)),l1=null,m1=0,n1=0;;)if(n1<m1){var Lna=l1.X(null,n1);$CLJS.xF(Lna,h1);n1+=1}else{var o1=$CLJS.y(k1);if(o1){var p1=o1;if($CLJS.Ad(p1)){var q1=$CLJS.lc(p1),Mna=$CLJS.mc(p1),Nna=q1,Ona=$CLJS.D(q1);k1=Mna;l1=Nna;m1=Ona}else{var Pna=$CLJS.A(p1);$CLJS.xF(Pna,h1);k1=$CLJS.B(p1);l1=null;m1=0}n1=0}else break}
$CLJS.F0.m(null,h1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Kna,c);a:{var e=e1;e1=!0;try{var f=$CLJS.Ht([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.fu($CLJS.f0,a,b),d));break a}finally{e1=e}f=void 0}return f1(f)});$CLJS.G0.m(null,h1,function(){return"expression"});
$CLJS.J0.m(null,$CLJS.IH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.zF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.K0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.K0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.F0.m(null,$CLJS.pJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.f0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.Y0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.DA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=f1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.G0.m(null,$CLJS.pJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.H0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.Y0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.DA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.F0.m(null,$CLJS.sG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.f0.v(a,b,e,d)});$CLJS.G0.m(null,$CLJS.sG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.H0.j(a,b,d)});
$CLJS.Qna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ina(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.gS,f],null));return $CLJS.WW.l(d,e,Jna,$CLJS.H([$CLJS.FW($CLJS.xW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Rna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.eQ.h($CLJS.LW(d,e)));return null==f?null:$CLJS.Qk.g($CLJS.fu(g1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r1=function(){function a(d,e){return $CLJS.Fe($CLJS.eQ.h($CLJS.LW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.uW.m(null,$CLJS.tB,function(a){return a});
$CLJS.Sna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.cm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EW(t),m],null)},$CLJS.r1.g(d,e))),l=$CLJS.LW(d,e);d=$CLJS.Q0.j(d,e,l);return $CLJS.Fe($CLJS.d1(function(m){return $CLJS.Va(f)||$CLJS.Mk.g($CLJS.VK.h(m),$CLJS.KZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Tna=function(){function a(d,e,f){return $CLJS.wW(g1(d,e,j1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();