var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var HL,JL,LL,OL,TL;$CLJS.EL=function(a){return $CLJS.Wh($CLJS.q($CLJS.lC),a,$CLJS.tG)};$CLJS.FL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.e6=new $CLJS.M(null,"row","row",-570139521);$CLJS.f6=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.GL=new $CLJS.M(null,"object-id","object-id",-754527291);HL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.IL=new $CLJS.M(null,"operators","operators",-2064102509);JL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.KL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);LL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.ML=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.NL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
OL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.PL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.QL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.RL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.SL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
TL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.a7=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.i7=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(JL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.kH],null)],null)],null));$CLJS.X(TL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,JL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.IE],null)],null)],null));$CLJS.X(LL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pG],null),TL,$CLJS.zE],null));
$CLJS.X(OL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pG],null),JL,$CLJS.nL],null));
$CLJS.QG.g($CLJS.pG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pG],null),JL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.nL,$CLJS.zE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.jC(a)):null},$CLJS.wt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.nB,OL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,LL],null)],null)],null));$CLJS.xF($CLJS.pG,$CLJS.tG);$CLJS.TE.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.fF});$CLJS.EF($CLJS.tB,$CLJS.H([$CLJS.zE]));$CLJS.TE.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.fF});
$CLJS.xF($CLJS.tB,$CLJS.tG);$CLJS.X(HL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.zE],null)],null)],null));$CLJS.QG.g($CLJS.eG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.eG],null),HL,$CLJS.Yj],null));
$CLJS.TE.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.fF});$CLJS.xF($CLJS.eG,$CLJS.tG);$CLJS.EF($CLJS.OF,$CLJS.H([$CLJS.pt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null)],null)]));$CLJS.xF($CLJS.OF,$CLJS.tG);
$CLJS.EF($CLJS.kG,$CLJS.H([$CLJS.pt,$CLJS.fF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UK],null)],null)]));$CLJS.xF($CLJS.kG,$CLJS.tG);
$CLJS.X($CLJS.tG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.VE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Ht(", ",$CLJS.bH($CLJS.Jd,$CLJS.jl.g($CLJS.q($CLJS.lC),$CLJS.tG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.EL(a)}],null)],null));