var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var k4,m4,n4,o4,Opa;k4=function(a,b,c){var d=$CLJS.LW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.eG);d=$CLJS.BH($CLJS.Ok.j($CLJS.ch([c]),$CLJS.GE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.pE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Ls,c,$CLJS.MF,a,$CLJS.KL,b],null));return d};$CLJS.l4=function(a){return $CLJS.e2($CLJS.CG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
m4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);n4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);o4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Opa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.I0.m(null,$CLJS.eG,function(a,b){var c=$CLJS.Fe($CLJS.eG.h($CLJS.LW(a,b)));return $CLJS.n(c)?$CLJS.g2($CLJS.RE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.f0.v(a,b,v,$CLJS.g0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.f0.v(a,
b,t,$CLJS.g0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.L0.m(null,$CLJS.eG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.wB);e=$CLJS.J.g(e,$CLJS.Ei);c=$CLJS.I(c,2,null);c=k4(a,b,c);return $CLJS.fl.l($CLJS.H([$CLJS.e0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.VK,$CLJS.pL,$CLJS.i1,$CLJS.GE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.wB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null]))});
$CLJS.F0.m(null,$CLJS.eG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.f0.v(a,b,k4(a,b,c),d)});$CLJS.xF(n4,o4);
for(var p4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.qG],null)),q4=null,r4=0,s4=0;;)if(s4<r4){var Ppa=q4.X(null,s4);$CLJS.xF(Ppa,n4);s4+=1}else{var t4=$CLJS.y(p4);if(t4){var u4=t4;if($CLJS.Ad(u4)){var v4=$CLJS.lc(u4),Qpa=$CLJS.mc(u4),Rpa=v4,Spa=$CLJS.D(v4);p4=Qpa;q4=Rpa;r4=Spa}else{var Tpa=$CLJS.A(u4);$CLJS.xF(Tpa,n4);p4=$CLJS.B(u4);q4=null;r4=0}s4=0}else break}
$CLJS.F0.m(null,n4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.f0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.pE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.pE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.RE("Count");case "cum-count":return $CLJS.RE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.G0.m(null,n4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.L0.m(null,n4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.uI($CLJS.L0,o4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Uj,$CLJS.ZC)});$CLJS.F0.m(null,$CLJS.ZF,function(){return $CLJS.RE("Case")});
$CLJS.G0.m(null,$CLJS.ZF,function(){return"case"});$CLJS.xF(m4,o4);for(var w4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.jG,$CLJS.MG,$CLJS.VF,$CLJS.ik,$CLJS.DG,$CLJS.Yn,$CLJS.FG,$CLJS.CG,$CLJS.WF],null)),x4=null,y4=0,z4=0;;)if(z4<y4){var Upa=x4.X(null,z4);$CLJS.xF(Upa,m4);z4+=1}else{var A4=$CLJS.y(w4);if(A4){var B4=A4;if($CLJS.Ad(B4)){var C4=$CLJS.lc(B4),Vpa=$CLJS.mc(B4),Wpa=C4,Xpa=$CLJS.D(C4);w4=Vpa;x4=Wpa;y4=Xpa}else{var Ypa=$CLJS.A(B4);$CLJS.xF(Ypa,m4);w4=$CLJS.B(B4);x4=null;y4=0}z4=0}else break}
$CLJS.G0.m(null,m4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.F0.m(null,m4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.f0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.pE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.pE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.pE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.pE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.pE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.pE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.pE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.pE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.pE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.F0.m(null,$CLJS.aG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.pE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.f0.v(a,b,e,d)]))});$CLJS.G0.m(null,$CLJS.aG,function(){return"percentile"});
$CLJS.xF($CLJS.aG,o4);$CLJS.F0.m(null,$CLJS.KG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.pE("Sum of {0} matching condition",$CLJS.H([$CLJS.f0.v(a,b,e,d)]))});$CLJS.G0.m(null,$CLJS.KG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.H0.j(a,b,d))].join("")});$CLJS.xF($CLJS.KG,o4);$CLJS.F0.m(null,$CLJS.wG,function(){return $CLJS.RE("Share of rows matching condition")});
$CLJS.G0.m(null,$CLJS.wG,function(){return"share"});$CLJS.xF($CLJS.wG,o4);$CLJS.F0.m(null,$CLJS.yG,function(){return $CLJS.RE("Count of rows matching condition")});$CLJS.G0.m(null,$CLJS.yG,function(){return"count-where"});$CLJS.xF($CLJS.yG,o4);
$CLJS.L0.m(null,o4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.fl.l;d=$CLJS.n(d)?$CLJS.dm($CLJS.e0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iL],null)):null;var f=$CLJS.uI($CLJS.L0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.fl,$CLJS.H([d,a]))});
$CLJS.Zpa=function(){function a(d){return $CLJS.e2($CLJS.Wx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.e2($CLJS.Wx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.uW.m(null,$CLJS.eG,function(a){return a});
$CLJS.D4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.jC(f),$CLJS.ZK))f=$CLJS.wW(f);else return $CLJS.h2(d,e,$CLJS.eG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.E4=function(){function a(d,e){return $CLJS.Fe($CLJS.eG.h($CLJS.LW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.F4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.eG.h($CLJS.LW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.e0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.wB.h(l);return m.call($CLJS.R,$CLJS.d2(l,$CLJS.Ei,$CLJS.n(t)?t:$CLJS.zj),$CLJS.VK,$CLJS.pL,$CLJS.H([$CLJS.i1,$CLJS.GE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.F0.m(null,$CLJS.ZG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.VG);return $CLJS.uE.h(a.o?a.o():a.call(null))});$CLJS.M0.m(null,$CLJS.ZG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.iG);b=$CLJS.J.g(d,$CLJS.VG);c=$CLJS.J.g(d,$CLJS.TG);d=$CLJS.J.g(d,$CLJS.RZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.D0,$CLJS.bB(a),$CLJS.H([$CLJS.B0,c]));return null!=d?$CLJS.R.j(a,$CLJS.n0,d):a});
$CLJS.$pa=function(){function a(d,e){var f=function(){var m=$CLJS.kA.h($CLJS.z1($CLJS.Z_(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.LW(d,e),l=$CLJS.Q0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Ok.j($CLJS.kf(function(m){m=$CLJS.XG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Wl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.TG),u=$CLJS.J.g(m,$CLJS.RG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.Ns))return $CLJS.R.j(m,$CLJS.IW,l);t=$CLJS.Fe($CLJS.d1(function(v){return $CLJS.B_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.IW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.Dj,$CLJS.ZG)})),$CLJS.$G))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.aqa=function(){function a(d,e){return $CLJS.vW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG.h(d),$CLJS.N,$CLJS.xW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.TG.h(d)))return $CLJS.vW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG.h(d),$CLJS.N],null));var e=$CLJS.iG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Opa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();