var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var k7,m7,n7,gra,hra,ira,q7,r7,t7,u7,jra,v7,kra,lra;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.wB,$CLJS.wB.h(a),$CLJS.Ei,$CLJS.EA($CLJS.Ei,$CLJS.wB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tB,b,$CLJS.EA($CLJS.yE,$CLJS.T)(a)],null)};$CLJS.l7=function(a){return $CLJS.x5.h(a)};m7=function(a){return $CLJS.R.j(a,$CLJS.Dj,$CLJS.O4)};
n7=function(a,b){return $CLJS.E.g($CLJS.tE.h(a),$CLJS.dm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,$CLJS.sE,$CLJS.JE],null)))};gra=function(a){return $CLJS.u1(a,new $CLJS.h(null,1,[$CLJS.KE,$CLJS.zh],null))};
hra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.BH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.OA($CLJS.Jy);if($CLJS.n($CLJS.NA("metabase.lib.field",c))){var d=$CLJS.pE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Qk.g($CLJS.QZ,b)]))]));d instanceof Error?$CLJS.MA("metabase.lib.field",c,$CLJS.Fw(),d):$CLJS.MA("metabase.lib.field",
c,$CLJS.Fw.l($CLJS.H([d])),null)}return null};
ira=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.f2(a,b),f=$CLJS.n(e)?$CLJS.LW(a,e):$CLJS.LW(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.FA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tV,$CLJS.IW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.UV.h(f);if($CLJS.n(u))return u;u=$CLJS.kP.h(f);if($CLJS.n(u))return u;u=$CLJS.eQ.h(f);return $CLJS.n(u)?u:$CLJS.ZE.h(f)}())?$CLJS.Q0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.OA($CLJS.Jy);if($CLJS.n($CLJS.NA("metabase.lib.field",
m))){var t=$CLJS.pE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.MA("metabase.lib.field",m,$CLJS.Fw(),t):$CLJS.MA("metabase.lib.field",m,$CLJS.Fw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?hra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.G3($CLJS.Gk.l(l,$CLJS.Yi,$CLJS.H([$CLJS.hL,$CLJS.VW,$CLJS.PW])),null),$CLJS.T,function(){var m=$CLJS.QZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.VK,$CLJS.EZ):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.zP);var f=$CLJS.I(c,2,null);c=$CLJS.fl.l($CLJS.H([function(){var k=$CLJS.wB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.wB,k],null):null}(),function(){var k=$CLJS.EA($CLJS.Ei,$CLJS.wB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Ei,k],null):null}(),function(){var k=$CLJS.AR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.VW,k],null):null}(),function(){var k=$CLJS.vG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.PW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.k2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.TK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.TK,$CLJS.T,f],null):function(){var k=ira(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.TK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.G3(c,d):c};r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.PW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.yH,b):b)?$CLJS.vj:$CLJS.EA($CLJS.Ei,$CLJS.wB)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.GE),l=$CLJS.J.g(f,$CLJS.wB),m=$CLJS.J.g(f,$CLJS.AR),t=$CLJS.J.g(f,$CLJS.Ei);e=$CLJS.J.g(f,$CLJS.zP);var u=$CLJS.J.g(f,$CLJS.KN),v=$CLJS.J.g(f,$CLJS.vG),x=$CLJS.fl.l;k=new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.TK,$CLJS.i1,k],null);f=$CLJS.uE.h(f);a=$CLJS.n(f)?f:$CLJS.f0.j(a,b,d);c=x.call($CLJS.fl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.uE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Ei,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.wB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.PW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.VW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.gL,u):m;return $CLJS.n(e)?$CLJS.G3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.ri.h(d),c)},a))?$CLJS.Qk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Gk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ri.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.P1.h(a))?null:function(){var d=$CLJS.VK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.TZ,null,$CLJS.ZZ,null,$CLJS.EZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.wB,$CLJS.wB.h(a),$CLJS.Ei,r7(a)],null),function(){var d=$CLJS.I1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.zP,d],null):null}(),function(){var d=$CLJS.PW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.vG,d],
null):null}(),function(){var d=$CLJS.TW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.TW,d],null):null}(),function(){var d=$CLJS.VW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.AR,d],null):null}(),function(){var d=$CLJS.gL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.KN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.EA($CLJS.QZ,$CLJS.T):$CLJS.EA($CLJS.Yi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,b],null)};
jra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.VK.h(c),$CLJS.KZ)},$CLJS.Q0.v(a,b,$CLJS.LW(a,b),new $CLJS.h(null,3,[$CLJS.y0,!1,$CLJS.E0,!0,$CLJS.u0,!1],null)))};v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);kra=new $CLJS.M(null,"earliest","earliest",-1928154382);lra=new $CLJS.M(null,"latest","latest",24323665);var ora;$CLJS.v1.m(null,$CLJS.pG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.u1(c,new $CLJS.h(null,2,[$CLJS.vG,$CLJS.zh,$CLJS.AR,gra],null)),a],null)});var o7=!1,w7=function w7(a,b){var d=$CLJS.B1(a,v7.h(b));a=$CLJS.n(v7.h(d))?w7.g?w7.g(a,d):w7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Rk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.J0.m(null,$CLJS.TK,function(a,b,c){return r7(c)});$CLJS.J0.m(null,$CLJS.pG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.vG);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.PW,d):c;return $CLJS.K0.j(a,b,c)});$CLJS.L0.m(null,$CLJS.TK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.L0.m(null,$CLJS.pG,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(v7.h(b))?w7(a,b):b});
$CLJS.F0.m(null,$CLJS.TK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.uE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ri),l=$CLJS.J.g(c,$CLJS.VW),m=$CLJS.J.g(c,$CLJS.b_),t=$CLJS.J.g(c,$CLJS.gL),u=$CLJS.J.g(c,$CLJS.hL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.G1.g($CLJS.BB,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.g0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.B1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.IA($CLJS.uE.h($CLJS.i0.j(a,b,t)),$CLJS.XW,"")):(u=$CLJS.H1(a,u),a=$CLJS.f0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.I1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.eB($CLJS.IA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.u5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.F0.m(null,$CLJS.pG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.AR);var k=$CLJS.J.g(f,$CLJS.zP),l=$CLJS.J.g(f,$CLJS.vG);f=$CLJS.J.g(f,$CLJS.KN);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.b_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ri,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.VW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.gL,f):e;return $CLJS.n(e)?$CLJS.f0.v(a,b,e,d):$CLJS.RE("[Unknown Field]")});
$CLJS.G0.m(null,$CLJS.TK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.G0.m(null,$CLJS.pG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.H0.j(a,b,c):"unknown_field"});
$CLJS.M0.m(null,$CLJS.TK,function(a,b,c){return $CLJS.fl.l($CLJS.H([function(){var d=$CLJS.uI($CLJS.M0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.VK.h(c),$CLJS.TZ)?function(){var d=$CLJS.a_.h(c);return $CLJS.n(d)?(d=$CLJS.b0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.UW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.uE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.$0.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.vG.h(b)});
$CLJS.$0.m(null,$CLJS.TK,function(a){return $CLJS.PW.h(a)});
$CLJS.Z0.m(null,$CLJS.pG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.yH,b),e=$CLJS.vC($CLJS.TW,$CLJS.Ei,$CLJS.wB)(c);c=$CLJS.R.l(c,$CLJS.vG,b,$CLJS.H([$CLJS.Ei,d?$CLJS.vj:e,$CLJS.TW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,a],null)}b=$CLJS.TW.h(c);c=$CLJS.n(b)?$CLJS.Gk.g($CLJS.R.j(c,$CLJS.Ei,b),$CLJS.TW):c;c=$CLJS.Gk.g(c,$CLJS.vG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,a],null)});
$CLJS.Z0.m(null,$CLJS.TK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.PW,b,$CLJS.H([$CLJS.TW,$CLJS.vC($CLJS.TW,$CLJS.Ei,$CLJS.wB)(a)])):$CLJS.Gk.l(a,$CLJS.PW,$CLJS.H([$CLJS.TW]))});$CLJS.b1.m(null,$CLJS.pG,function(a,b,c){return $CLJS.c1.j(a,b,q7(a,b,c))});
$CLJS.b1.m(null,$CLJS.TK,function(a,b,c){if($CLJS.Mk.g($CLJS.VK.h(c),$CLJS.KZ)){a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(c);b=null==c?null:$CLJS.YN.h(c);if(null==b)var d=null;else try{var e=$CLJS.xD.h($CLJS.lj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,kra),l=$CLJS.J.g(f,lra),m=$CLJS.zna.l($CLJS.H([$CLJS.X0.h(k),$CLJS.X0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Tr.g?$CLJS.Tr.g(1,m):$CLJS.Tr.call(null,1,m))?$CLJS.Ti:$CLJS.n($CLJS.Tr.g?$CLJS.Tr.g(31,m):$CLJS.Tr.call(null,31,m))?$CLJS.du:$CLJS.n($CLJS.Tr.g?
$CLJS.Tr.g(365,m):$CLJS.Tr.call(null,365,m))?$CLJS.nj:$CLJS.wj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.xC(a,$CLJS.xD)?$CLJS.Hna:$CLJS.xC(a,$CLJS.YC)?$CLJS.Gna:$CLJS.xC(a,$CLJS.XD)?$CLJS.Fna:$CLJS.Cf;d=$CLJS.n(d)?t7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.PW.h(c))?t7(d,$CLJS.n0,$CLJS.PW.h(c)):d}return $CLJS.Cf});
$CLJS.x5.m(null,$CLJS.pG,function(a){var b=null==a?null:$CLJS.NE(a);b=null==b?null:$CLJS.AR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Dj,$CLJS.w5,$CLJS.H([$CLJS.v5,function(c,d){return q7(c,d,a)}]))});$CLJS.x5.m(null,$CLJS.TK,function(a){var b=null==a?null:$CLJS.VW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Dj,$CLJS.w5,$CLJS.H([$CLJS.v5,$CLJS.Ue(a)]))});$CLJS.r5.m(null,$CLJS.pG,function(a,b){return $CLJS.OE(a,$CLJS.ME,$CLJS.H([$CLJS.AR,b]))});
$CLJS.r5.m(null,$CLJS.TK,function(a,b){return $CLJS.ME(a,$CLJS.VW,b)});$CLJS.y5.m(null,$CLJS.pG,function(a,b,c){return $CLJS.z5.j(a,b,q7(a,b,c))});
$CLJS.y5.m(null,$CLJS.TK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Ei);var d=$CLJS.J.g(b,$CLJS.YN),e=$CLJS.J.g(b,$CLJS.Uj);if($CLJS.Mk.g($CLJS.VK.h(b),$CLJS.KZ)){var f=function(){var m=null==a?null:$CLJS.z1($CLJS.Z_(a));m=null==m?null:$CLJS.kA.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.AR)}(),k=$CLJS.FA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,$CLJS.Oj],null)),l=$CLJS.l7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=n7(S,l)?$CLJS.R.j(Z,$CLJS.n0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.n0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.Yn.h(k);return $CLJS.n(t)?$CLJS.ik.h(k):t}return f}())?null:$CLJS.xC(e,$CLJS.xj)?$CLJS.Qk.g(m7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.t5(),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 0.1 degrees"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 1 degree"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,1],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 10 degrees"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,10],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("Bin every 20 degrees"),
$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.JE,$CLJS.JE,20],null)],null)],null)):$CLJS.xC(c,$CLJS.Oj)&&!$CLJS.xC(e,$CLJS.mi)?$CLJS.Qk.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.t5(),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("10 bins"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.sE,$CLJS.sE,10],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("50 bins"),$CLJS.tE,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.sE,$CLJS.sE,50],null)],null),new $CLJS.h(null,2,[$CLJS.uE,$CLJS.RE("100 bins"),$CLJS.tE,new $CLJS.h(null,2,
[$CLJS.KE,$CLJS.sE,$CLJS.sE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.uW.m(null,$CLJS.pG,function(a){return a});
$CLJS.uW.m(null,$CLJS.TK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.VK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.GE,$CLJS.p.h($CLJS.QE()),$CLJS.Ei,$CLJS.EA($CLJS.Ei,$CLJS.wB)(a)],null),a=$CLJS.i1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.yE.h(a))?k7(a):u7(a);default:return u7(a)}});
$CLJS.mra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Qk.g($CLJS.wW,k));var l=jra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Wl(function(t){return $CLJS.q2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Pk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.wW),m):null;return $CLJS.WW.l(e,f,$CLJS.ME,$CLJS.H([$CLJS.ZE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.x7=function(){function a(d,e){return $CLJS.ZE.h($CLJS.LW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.nra=function(){function a(d,e){var f=$CLJS.Q0.v(d,e,$CLJS.LW(d,e),new $CLJS.h(null,3,[$CLJS.y0,!1,$CLJS.E0,!1,$CLJS.u0,!1],null)),k=$CLJS.x7.g(d,e);return $CLJS.td(k)?$CLJS.Qk.g(function(l){return $CLJS.R.j(l,$CLJS.RZ,!0)},f):$CLJS.E3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
ora=function(){function a(d,e,f){var k=$CLJS.LW(d,e),l=$CLJS.E.g($CLJS.jC(f),$CLJS.eG)?$CLJS.O0:$CLJS.Q0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.q2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.pra=function(){function a(d,e,f){f=$CLJS.AZ.j(d,e,f);return ora.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();