var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var BZ,Lma,Mma,Nma,Oma,Pma,HZ,Qma,Rma,Sma,JZ,Tma,Uma,Vma,OZ,PZ,Wma,Xma,Yma,XZ,Zma,$ma,ana,$Z,bna,cna,dna,ena;BZ=function(){};$CLJS.CZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(BZ,a):$CLJS.$a(BZ,a)};Lma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.DZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Mma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Nma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.EZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.FZ=new $CLJS.M(null,"database-id","database-id",1883826326);Oma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.GZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Pma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);HZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Qma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Rma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Sma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.aL=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.IZ=new $CLJS.M(null,"dataset","dataset",1159262238);JZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Tma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.KZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.LZ=new $CLJS.M(null,"definition","definition",-1198729982);Uma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.MZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Vma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.NZ=new $CLJS.M("source","joins","source/joins",1225821486);OZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
PZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.QZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.fL=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Wma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.RZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Xma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Yma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.SZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.TZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.UZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.VZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.WZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);XZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Zma=new $CLJS.M(null,"details","details",1956795411);$CLJS.YZ=new $CLJS.M("source","fields","source/fields",-649667981);$ma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.ZZ=new $CLJS.M("source","native","source/native",-1444604147);
ana=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$Z=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.a_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);bna=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);cna=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
dna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);ena=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.b_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.c_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(OZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ts,$CLJS.TZ,$CLJS.ZZ,$CLJS.EZ,$CLJS.GZ,$CLJS.YZ,$CLJS.pL,$CLJS.jL,$CLJS.NZ,$CLJS.KZ,$CLJS.MZ],null));$CLJS.X(XZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,ena],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cL,$CLJS.nL],null)],null));
$CLJS.X(HZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,bna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.it,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ns],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Pma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Ns],null)],null)],null));
$CLJS.X($CLJS.aL,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.nL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Yj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ei,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.IE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b_,
new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.a_,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.dL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,new $CLJS.h(null,2,[$CLJS.Yn,1,$CLJS.ik,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Wma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dna,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HZ],null)],null)],null)],null));
$CLJS.X($Z,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SZ,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[Sma,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.IE],null)],null)],null)],null)],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UZ,$CLJS.Ms],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uma,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SZ,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Z],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SK,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,
$CLJS.zE],null)],null)],null));
$CLJS.X($ma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.oL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FZ,$CLJS.eL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.hk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,
new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.hk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IZ,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.YK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(Xma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.XK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Qt,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null)],null));
$CLJS.X(Yma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.UK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hL,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Qt,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null)],null));
$CLJS.X(ana,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.YK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,
1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.zE],null)],null)],null));
$CLJS.X(Mma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,$CLJS.eL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.hk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.c_,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Wi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kA,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.Wi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vma,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.hk],null)],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.wt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.CZ},Oma,$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.xk,$CLJS.ti,$CLJS.jk,$CLJS.nk],[Lma,cna,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Xq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.CZ)?$CLJS.CZ.H:null]))],null));
$CLJS.X(Qma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.wt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null)],null)],null)],null));